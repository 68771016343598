<template>
  <div class="input-container-size">
    <label class="mdc-text-field mdc-text-field--outlined" :class="input_key_to_save_user_values">
      <div v-show="character_counter_permission" class="mdc-text-field-character-counter" :class="input_class_character_counter"></div>
      <span class="mdc-notched-outline">
        <span class="mdc-notched-outline__leading"></span>
        <span class="mdc-notched-outline__notch">
          <span class="mdc-floating-label" id="my-label-id">{{input_label}}</span>
        </span>
        <span class="mdc-notched-outline__trailing"></span>
      </span>
      <input v-if="computed_value_permission" :value="computed_value" type="text" class="mdc-text-field__input" aria-labelledby="my-label-id" aria-controls="my-helper-id" aria-describedby="my-helper-id" :maxlength="length">
      <input v-else :type="input_type" class="mdc-text-field__input" aria-labelledby="my-label-id" aria-controls="my-helper-id" aria-describedby="my-helper-id" :maxlength="length">
      <i v-show="icon_permission" class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing" tabindex="0" role="button" :class="icon_class">{{ icon_name }}</i>
    </label>
    <div class="mdc-text-field-helper-line">
      <div v-show="helper_permission" id="username-helper-text" class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" aria-hidden="true"></div>
    </div>
  </div>
</template>




<script>
  import { MDCTextField } from '@material/textfield';
  import { MDCTextFieldIcon } from '@material/textfield/icon';
  import { MDCTextFieldHelperText } from '@material/textfield/helper-text';
  import { MDCTextFieldCharacterCounter } from '@material/textfield/character-counter';

  export default {
    name: 'InputTextOutline',
    data() {
      return {
        textfield: {},
        textarea: {},
        texticon: {},
        characterCounter: {},
      };
    },
    props: {
      length: { type: Number },
      has_focus: { type: Boolean },
      test_field_is_numeric: { type: Boolean },
      input_type: { type: String },

      icon_name: { type: String },
      input_label: { type: String },
      input_helper: { type: String },
      
      icon_class: { type: String },
      input_key_to_save_user_values: { type: String },
      input_key: { type: String },
      input_class_character_counter: { type: String },

      permission: { type: Boolean },
      icon_permission: { type: Boolean },
      helper_permission: { type: Boolean },
      character_counter_permission: { type: Boolean },

      computed_value: { type: String },
      computed_value_permission: { type: Boolean },
    },
    mounted() {
      new MDCTextFieldHelperText(document.querySelector('.mdc-text-field-helper-text'));
      this.texticon = new MDCTextFieldIcon(document.querySelector('.mdc-text-field__icon' + '.' + this.icon_class));
      this.textfield = new MDCTextField(document.querySelector('.mdc-text-field' + '.' + this.input_key_to_save_user_values));
      this.characterCounter = new MDCTextFieldCharacterCounter(document.querySelector('.mdc-text-field-character-counter' + '.' + this.input_class_character_counter));
     
      this.textfield.foundation.adapter.registerInputInteractionHandler('blur', () => {
        this.validationNumberTextField();
    });

      this.textfield.helperTextContent = this.input_helper;

      if(this.has_focus) { this.textfield.focus(); }
      if(!this.permission) { this.textfield.foundation.setDisabled(true); }
      
      this.texticon.foundation.setContent(this.icon);
      
      this.textfield.foundation.handleInput = () => {
        this.validationNumberTextField();
        this.handleCharacterCounter();
        this.handleEmit();
      };
    },
    methods: {
        handleEmit() {
          this.$emit('_emit_method_order_input', [this.textfield.value, this.input_key_to_save_user_values]);
        },

        handleCharacterCounter() {
            if (this.character_counter_permission) {
                this.characterCounter.foundation.setCounterValue(this.textfield.value.length, this.length);
            }
        },

        validationNumberTextField() {
            if (this.test_field_is_numeric) {
                this.textfield.valid = true;
                if (isNaN(Number(this.textfield.value))) {
                    this.textfield.valid = false;
                    this.textfield.helperTextContent = 'Debes de escribir sólo números';
                    this.textfield.getDefaultFoundation().helperText_.adapter.addClass('mdc-text-field-helper-text--validation-msg');
                } else {
                    this.textfield.helperTextContent = this.input_helper;
                    this.textfield.getDefaultFoundation().helperText_.adapter.removeClass('mdc-text-field-helper-text--validation-msg');
                }
            }
        }
     }
  }
</script>


<style lang="scss" scoped>
    @use "@material/floating-label/mdc-floating-label";
    @use "@material/line-ripple/mdc-line-ripple";
    @use "@material/notched-outline/mdc-notched-outline";
    @use "@material/textfield";
    @use "@material/textfield/helper-text";
    @use "@material/textfield/character-counter";
    @use "@material/textfield/icon";

    @include icon.icon-core-styles;
    @include textfield.core-styles;

    .profile .mdc-text-field	{
      @include textfield.outlined-height(34px);
      width: 85%;
    }
    .profile .mdc-text-field.mdc-text-field--disabled {
      width: 90%;
    }

    .error-report .mdc-text-field	{
      @include textfield.outlined-height(150px);
      @include textfield.placeholder-color(var(--color-text-primary));
      @include textfield.fill-color(var(--color-text-primary));
      border-radius: 5px;
      padding: 0;
      text-decoration: none;

      textarea {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-decoration: none;
        resize: none;
      }

    }
    .error-report .input-container-size {
      width: 100%;
    }

    #stock-comments .mdc-text-field {
      @include textfield.outlined-height(70px);
      @include textfield.placeholder-color(var(--color-text-primary));
      @include textfield.fill-color(var(--color-text-primary));
      border-radius: 5px;
      padding: 0;

      textarea {
        resize: none;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
    }

    #stock-comments .input-container-size {
      width: 100%;
    }

    textarea {
      resize: none;
    }
    
    .mdc-text-field {
    @include textfield.disabled-fill-color(var(--color-surface));
    @include textfield.ink-color(var(--color-text-primary));
    @include textfield.label-color(var(--color-text-primary));
    @include textfield.placeholder-color(var(--color-text-primary));
    @include textfield.disabled-ink-color(var(--color-text-primary));
    @include textfield.outline-color(var(--color-text-primary));
    @include textfield.hover-outline-color(var(--color-text-primary));  // Add this line
    --mdc-theme-primary: #0E5F85FF;
}

    .mdc-text-field-character-counter {
    margin: 0px;}

    .mdc-text-field-helper-line.red {
    padding: 0;}

    .mdc-text-field-character-counter {
    color: rgba(0, 0, 0, 0.6);}

    .mdc-text-field {
    padding: 0 0 0 15px;}

    .mdc-text-field--outlined .mdc-text-field__resizer {
      resize: none;
    } 


    // .mdc-text-field--invalid {
    //     @include textfield.label-color(rgba(255, 0, 0, 1));
    //     @include textfield.ink-color(rgba(255, 0, 0, 1));
    //     @include textfield.caret-color(rgba(255, 0, 0, 1)); //esto modifica el color del cursor

    //     .mdc-text-field-helper-text {
    //         @include helper-text.helper-text-color(rgba(255, 0, 0, 1));
    //     }
    // }

    @media (max-width: 599px){
    .mdc-text-field	 {
        width: 100%;
        min-width: 100px;}
    }

    @media (min-width: 600px) and (max-width: 839px) {
    .mdc-text-field	 {
        width: 100%;
        min-width: 180px;}
    }

    @media (min-width: 840px){
    .mdc-text-field	 {
        width: 100%;
        min-width: 100px;}
    }
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
       -moz-appearance:textfield;
    }

    #alarms{
      .value_input_key_to_save{
        width: 100% !important;
        margin-left: 20px;
      }
    }
</style>
